import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from './context/Context';
import { getItemFromStore, setItemToStore } from './helpers/utils';

const Main = props => {


  const [currency, setCurrency] = useState('$');
  const [user, setUser] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(getItemFromStore('isAuthenticated', false));

  const value = {
    user,
    setUser,
    isAuthenticated,
    setIsAuthenticated,
    currency,
    setCurrency
  };

  const setAuthMode = mode => {
    setItemToStore(mode, value[mode]);

  };

  useEffect(() => {
    setAuthMode('isAuthenticated');
     // eslint-disable-next-line
  }, [isAuthenticated]);




  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

Main.propTypes = { children: PropTypes.node };

export default Main;
