import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Route, Switch, NavLink } from "react-router-dom";
import loadable from "@loadable/component";
import SideNav from "../components/SideNav";
import Master from "../pages/Master";

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));


const DashboardLayout = ({ location }) => {
  
  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  
  return (

<Fragment>

    <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
    <NavLink className="navbar-brand col-md-2 mr-0" to="#">Gropod</NavLink>
    <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
    </button>
    
    
    <ul className="navbar-nav px-3">
        <li className="nav-item text-nowrap">
        <NavLink className="nav-link" to="/auth/logout">Sign out</NavLink>
        </li>
    </ul>
</nav>


    <div className="container-fluid p-0">

      
    <div >
      <SideNav></SideNav>

    <div className="col-md-9 ml-sm-auto col-lg-10 py-3 px-5">
        <Switch>
          <Route path="/" exact component={Master} />
          <DashboardRoutes/>
        </Switch>
      </div>
    </div>
    </div>
    </Fragment>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
