//===============================
// Numbers
//===============================

/*

function currencyFormatter(cell, row) {
  return `$ ${cell}`;
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


  function imageFormatter(cell, row) {
    return `<img width="60px" src="${cell}"/>`;
  }
  

function getParsedDate(date){
  date = String(date).split(' ');
  var days = String(date[0]).split('-');
  var hours = String(date[1]).split(':');
  return [parseInt(days[0]), parseInt(days[1])-1, parseInt(days[2]), parseInt(hours[0]), parseInt(hours[1]), parseInt(hours[2])];
}*/


export const isIterableArray = array => Array.isArray(array) && !!array.length;

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = localStorage) =>
  JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToStore = (key, payload, store = localStorage) => store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = localStorage) =>
  parseFloat((escape(encodeURIComponent(JSON.stringify(store))).length / (1024 * 1024)).toFixed(2));


// function getParsedDate(strDate){
//   var strSplitDate = String(strDate).split(' ');
//   var date = new Date(strSplitDate[0]);
//   // alert(date);
//   var dd = date.getDate();
//   var mm = date.getMonth() + 1; //January is 0!

//   var yyyy = date.getFullYear();
//   if (dd < 10) {
//       dd = '0' + dd;
//   }
//   if (mm < 10) {
//       mm = '0' + mm;
//   }
//   date =  dd + "-" + mm + "-" + yyyy;
//   return date.toString();
// }

