import React from 'react';
import './App.css';
import Layout from './layouts/Layout';
import 'react-toastify/dist/ReactToastify.min.css';
import { BrowserRouter as Router } from "react-router-dom";

const App = () => {
    return (

    <Router>
      <Layout />
    </Router>
        
    );
}

export default App
