import React, {useEffect, useState} from 'react';

import SeedpodTable from "./SeedpodTable";
import SeedpodTableNext from "./SeedpodTableNext";

import HTTP from "../../hoc/HTTP";
const SEEDPOD_SERVICE_URL = `${process.env.REACT_APP_API_BASE_URL}/seedpod`;


function Seedpods() {
    const [data, setData] = useState({seedpods: [], isFetching: false});

    const addSeedpod = (id, name, description, weeks, img) => {
        let tmp = [...data.seedpods]
        tmp.push({ id: id, name: name, description: description, weekstoharvest: weeks, img: img })
        setData({ ...data, seedpods: tmp })
    }

    useEffect(() => {
        const fetchSeedpods = async () => {
            try {
                setData({isFetching: true});
                const response = await HTTP.get(SEEDPOD_SERVICE_URL);
                setData({seedpods: response.data, isFetching: false});
            } catch (e) {
                console.log(e);
                setData({isFetching: false});
            }
        };
        fetchSeedpods();
    }, []);

    
    return <SeedpodTableNext data={data.seedpods} isFetching={data.isFetching} addSeedpod={addSeedpod} />
    
}

export default Seedpods
