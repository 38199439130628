import React from 'react';
import PropTypes from 'prop-types';
import { cssTransition } from 'react-toastify';

import {X} from 'react-feather';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//<FontAwesomeIcon icon="times" className="my-2 fs--2" style={{ opacity: 0.5 }} onClick={closeToast} />

export const Fade = cssTransition({ enter: 'fadeIn', exit: 'fadeOut' });

export const CloseButton = ({ closeToast }) => (
  
  <X  onClick={closeToast}/>
  
);

CloseButton.propTypes = { closeToast: PropTypes.func };
