import React from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
//import '../css/Table.css'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { Link } from "react-router-dom";

//import { currencyFormatter, numberWithCommas } from '../../helpers/utils';


function detailLinkFormatter(cell, row) {
    return <Link to={`/product/${row.permalink}`}><strong>{cell}</strong></Link>;
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  function currencyFormatter(cell, row) {
    return `$ ${cell}`;
  }
  


  function imageFormatter(cell, row) {
    return `<img width="60px" src="${cell}"/>`;
  }



const ProductTable = (props) => {
    return (
        <div>
            <BootstrapTable data={props.data}  insertRow={ true }>
                <TableHeaderColumn hidden isKey dataField='id'></TableHeaderColumn>
                <TableHeaderColumn width='200'   dataField='name' dataFormat={detailLinkFormatter}>Name</TableHeaderColumn>
                <TableHeaderColumn dataField='shortdescription'>Description</TableHeaderColumn>
                <TableHeaderColumn dataField='price' dataFormat={numberWithCommas}>Price</TableHeaderColumn>
                <TableHeaderColumn dataField='saleprice' dataFormat={currencyFormatter}>Sales Price</TableHeaderColumn>
                <TableHeaderColumn dataField='thumbnail' dataFormat={imageFormatter}>Thumbnail</TableHeaderColumn>
                <TableHeaderColumn dataField='permalink'>Permalink</TableHeaderColumn>
                <TableHeaderColumn dataField='rating'>Rating</TableHeaderColumn>
            </BootstrapTable>
            <p>{props.isFetching ? 'Fetching Products...' : ''}</p>
        </div>
    )
};

export default ProductTable
