import React, { Fragment } from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
//import '../css/Table.css'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { Link } from "react-router-dom";


function detailLinkFormatter(cell, row) {
    return <Link to={`/seedpod/${row.id}`}><strong>{cell}</strong></Link>;
  }


  function imageFormatter(cell, row) {
    return `<img width="60px" src="${cell}"/>`;
  }



const SeedPodTable = (props) => {
    return (
        <Fragment>
            <BootstrapTable data={props.data}  insertRow={ true }>
                <TableHeaderColumn hidden isKey dataField='id'></TableHeaderColumn>
                <TableHeaderColumn width='200'   dataField='name'  dataFormat={detailLinkFormatter}>Name</TableHeaderColumn>
                <TableHeaderColumn dataField='description'>Description</TableHeaderColumn>
                <TableHeaderColumn dataField='weekstoharvest' width='150'>Weeks to Harvest</TableHeaderColumn>
                <TableHeaderColumn dataField='img' dataFormat={imageFormatter}>Image</TableHeaderColumn>
            </BootstrapTable>
            <p>{props.isFetching ? 'Fetching Seedpods...' : ''}</p>
        </Fragment>
    )
};

export default SeedPodTable
