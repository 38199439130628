import React from 'react';
import { NavLink } from 'react-router-dom'


const SideNav = (props) => {
    return (




<nav id="sidebarMenu" className="col-2 d-md-block bg-light sidebar collapse">

            <div className="sidebar-sticky">

            <ul className="nav flex-column">
            <li className="nav-item">
                    <NavLink to="/" aria-current="page" className="nav-link active">
                    <span data-feather="home"></span>
                    Dashboard
                    </NavLink>
                </li>
                </ul>


            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Administration</span>
                <NavLink className="d-flex align-items-center text-muted" to="/products" aria-label="Add a new report">
                    <span data-feather="plus-circle"></span>
                </NavLink>
                </h6>


                <ul className="nav flex-column">




                    


                <li className="nav-item">
                    <NavLink className="nav-link" to="/products">
                    <span data-feather="shopping-cart"></span>
                    Products
                    </NavLink>
                </li>


                <li className="nav-item">
                    <NavLink className="nav-link" to="/seedpods">
                    <span data-feather="layers"></span>
                    Seedpods
                    </NavLink>
                </li> 

                <li className="nav-item">
                    <NavLink className="nav-link" to="/seedpod-packages">
                    <span data-feather="shopping-bag"></span>
                    Seedpod Packages
                    </NavLink>
                </li>




                <li className="nav-item">
                    <NavLink className="nav-link" to="/subscription-sizes">
                    <span data-feather="layers"></span>
                    Subscription Sizes
                    </NavLink>
                </li>                                  






                </ul>





                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>CRM</span>
                <NavLink className="d-flex align-items-center text-muted" to="/products" aria-label="Add a new report">
                    <span data-feather="plus-circle"></span>
                </NavLink>
                </h6>
                <ul className="nav flex-column">
                <li className="nav-item">
                    <NavLink className="nav-link" to="/orders">
                    <span data-feather="file"></span>
                    Orders
                    </NavLink>
                </li>                    
                <li className="nav-item">
                    <NavLink className="nav-link" to="/subscriptions">
                    <span data-feather="shopping-bag"></span>
                    Subscriptions
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/users">
                    <span data-feather="users"></span>
                    Users
                    </NavLink>
                </li>                


                </ul>


                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Telemetry</span>
                <NavLink className="d-flex align-items-center text-muted" to="/products" aria-label="Add a new report">
                    <span data-feather="plus-circle"></span>
                </NavLink>
                </h6>
                <ul className="nav flex-column mb-2">
                <li className="nav-item">
                    <NavLink className="nav-link " to="/gropods">
                    <span data-feather="file-text"></span>
                    Gropods
                    </NavLink>
                </li>
                </ul>
            </div>
            </nav>

)
};

export default SideNav
