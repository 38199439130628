import React, { useState } from 'react'

import Modal from 'react-bootstrap/Modal'

const CreateNewForm = ({ show, hide, handleSubmit, error }) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [weeksToHarvest, setWeeksToHarvet] = useState('')
    const [weight, setWeight] = useState('')
    const [img, setImg] = useState('')

    return (
        <Modal show={show} onHide={hide}>
            <Modal.Header closeButton>
              <Modal.Title>Create New</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={e => {
                e.preventDefault()
                handleSubmit(name, description, weeksToHarvest, img, weight)
              }}>
                <label>Name</label>
                <input required className="form-control" value={name} onChange={e => setName(e.target.value)} />
                <label>Description</label>
                <textarea required className="form-control" value={description} onChange={e => setDescription(e.target.value)} />
                <label>Weeks to Harvest</label>
                <input required className="form-control" value={weeksToHarvest} onChange={e => setWeeksToHarvet(e.target.value)} />
                <label>Weight</label>
                <input required className="form-control" value={weight} onChange={e => setWeight(e.target.value)} />
                <label>Image URL</label>
                <input required className="form-control" value={img} onChange={e => setImg(e.target.value)} />
                <input type="submit" className="form-control my-3" />
                { error && <p className="text-danger text-center my-1" >{error}</p> }
              </form>
            </Modal.Body>
          </Modal>
    )
}

export default CreateNewForm