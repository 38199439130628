import React from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { NavLink } from 'react-router-dom'

function detailLinkFormatter(cell, row) {
    return (
        <strong> <NavLink to={`subscription-size-detail/${row.id}`}>${cell} </NavLink></strong>
    )
  }


  function currencyFormatter(cell, row) {
    return `$${cell}`;
  }



const SubscriptionSizeTable = (props) => {
    return (
        <div>
            <BootstrapTable data={props.data}  version='4'  insertRow={ true }>
                <TableHeaderColumn hidden isKey dataField='id'></TableHeaderColumn>
                <TableHeaderColumn width='200'  dataField='name'  dataFormat={detailLinkFormatter}>Name</TableHeaderColumn>
                <TableHeaderColumn dataField='qty'>Qty</TableHeaderColumn>
                <TableHeaderColumn dataField='description'>Description</TableHeaderColumn>
                <TableHeaderColumn dataField='price'  dataFormat={currencyFormatter}>Monthly Price</TableHeaderColumn>
                <TableHeaderColumn dataField='type'>Type</TableHeaderColumn>
                <TableHeaderColumn dataField='current'>Current</TableHeaderColumn>
                <TableHeaderColumn dataField='savings'dataFormat={currencyFormatter}>Savings</TableHeaderColumn>

            </BootstrapTable>
            <p>{props.isFetching ? 'Fetching users...' : ''}</p>
        </div>
    )
};

export default SubscriptionSizeTable
