import React, { Fragment} from 'react';
import SeedpodPackages from "../components/seedpodpackage/SeedpodPackages";
import SubscriptionSizes from "../components/subscription-sizes/SubscriptionSizes";
import Products from "../components/product/Products"
import Seedpods from '../components/seedpod/Seedpods';


const Master = (props) => {
    return (
      <Fragment>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2 font-weight-bold">Dashboard</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group mr-2">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                Share
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                Export
              </button>
            </div>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary dropdown-toggle"
            >
              <span data-feather="calendar"></span>
              This week
            </button>
          </div>
        </div>
  
        <h5>Products</h5>
        <Products />
        <h5>Seedpod Packages</h5>
        <SeedpodPackages />
        <h5>Subscription Sizes</h5>
        <SubscriptionSizes />
        
        <Seedpods />
      </Fragment>
    );
};

export default Master
