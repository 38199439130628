import React, { Fragment, useState } from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button'
import CreateNew from './CreateNew'
import HTTP from '../../hoc/HTTP'

function detailLinkFormatter(cell, row) {
    return <Link to={`/seedpod-package-detail/${row.id}`}><strong>{cell}</strong></Link>;
}

function imageFormatter(cell, row) {
    return `<img width="60px" src="${cell}"/>`;
}

const SeedpodPackageTable = (props) => {

    const [showCreateNew, setShowCreateNew] = useState(false)
    const [createNewError, setCreateNewError] = useState(null)

    const handleSubmit = async(name, description, weeksToHarvest, img, weight) => {
        setCreateNewError(null)
        await HTTP.put(`${process.env.REACT_APP_API_BASE_URL}/seedpod-package`, {
            name: name,
            description: description,
            weekstoharvest: weeksToHarvest,
            img: img,
            weight: weight
        })
        .then(response => {
            props.addPackage(response.data)
            setShowCreateNew(false)
        })
        .catch(err => {
            console.error(err)
            if (err.response.data.message === "child \"weekstoharvest\" fails because [\"weekstoharvest\" must be a number]") {
                setCreateNewError('Weeks to harvest must be a number.')
            } else if (err.response.data.message === "child \"img\" fails because [\"img\" must be a valid uri]") {
                setCreateNewError('Image URL must be a valid URL.')
            } else if (err.response.data.message === "child \"weight\" fails because [\"weight\" must be a number]") {
                setCreateNewError('Weight must be a number.')
            } else if (err.response.data.message.includes("Duplicate active seedpod name.")) {
                setCreateNewError('A seedpod with that name already exists.')
            } else {
                setCreateNewError('Something went wrong. Try again later.')
            }
        })
    }

    return (
        <Fragment>
            <Button className="px-2 py-1 my-3" onClick={_ => setShowCreateNew(true)}>New</Button>
            <CreateNew show={showCreateNew} hide={_ => setShowCreateNew(false)} error={createNewError} handleSubmit={handleSubmit} />
            <BootstrapTable data={props.data}  version='4'>
                <TableHeaderColumn hidden isKey dataField='id'></TableHeaderColumn>
                <TableHeaderColumn width='200'   dataField='name'  dataFormat={detailLinkFormatter}>Name</TableHeaderColumn>
                <TableHeaderColumn dataField='description'>Description</TableHeaderColumn>
                <TableHeaderColumn dataField='weekstoharvest' width='150'>Weeks to Harvest</TableHeaderColumn>
                <TableHeaderColumn dataField='img' dataFormat={imageFormatter}>Image</TableHeaderColumn>
                <TableHeaderColumn dataField='included'>Included Pods</TableHeaderColumn>
            </BootstrapTable>
            <p>{props.isFetching ? 'Fetching users...' : ''}</p>
        </Fragment>
    )
};

export default SeedpodPackageTable
