import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CloseButton, Fade } from "../components/common/Toast";
import DashboardLayout from "./DashboardLayout";
import ErrorLayout from "./ErrorLayout";
import loadable from "@loadable/component";
import ProtectedRoute from "../hoc/ProtectedRoute";

import AppContext from "../context/Context";
const AuthBasicLayout = loadable(() => import("./AuthBasicLayout"));

const Layout = () => {
  
  const { isAuthenticated } = useContext(AppContext);

  useEffect(() => {
     AuthBasicLayout.preload();
   }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/auth" component={AuthBasicLayout} />
        <ProtectedRoute isAuthenticated={isAuthenticated} component={DashboardLayout} />  
        <Route path="/errors" component={ErrorLayout} />
      </Switch>
      <ToastContainer
        transition={Fade}
        closeButton={<CloseButton />}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Router>
  );
};

export default Layout;
