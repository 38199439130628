import React, {useEffect, useState} from 'react';
import HTTP from "../../hoc/HTTP";
import SubscriptionSizeTable from "./SubscriptionSizeTable";

const USER_SERVICE_URL = `${process.env.REACT_APP_API_BASE_URL}/subscription-size`;


function SubscriptionSizes() {
    const [data, setData] = useState({users: [], isFetching: false});

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setData({isFetching: true});
                const response = await HTTP.get(USER_SERVICE_URL);
                setData({users: response.data, isFetching: false});
            } catch (e) {
                console.log(e);
                setData({isFetching: false});
            }
        };
        fetchUsers();
    }, []);

    return <SubscriptionSizeTable data={data.users}  isFetching={data.isFetching}
    />
}

export default SubscriptionSizes
