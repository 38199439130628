import React, { Fragment, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Card,
  CardBody,
} from 'reactstrap';

import ButtonIcon from '../common/ButtonIcon';
import { Link } from 'react-router-dom';
import GropodCardHeader from '../common/GropodCardHeader';
import Modal from 'react-bootstrap/Modal'
import CreateNewForm from './CreateNewForm';
import HTTP from "../../hoc/HTTP";

const SEEDPOD_SERVICE_URL = `${process.env.REACT_APP_API_BASE_URL}/seedpod`;

  const detailLinkFormatter = (dataField, { id,name}) => (
    <Link to={`/seedpod/${id}`}><strong>{name}</strong></Link>
  )
  
  const imageFormatter = (dataField, {img,id,name}) => {
    return (
      <Link className="d-block h-100" to={`/seedpod/${id}`}>
        <img width="50px"
          className="img-fluid"
          src={img}
          alt={name}
        />
      </Link>
    )}


const amountFormatter = amount => {
    return (
      <Fragment>
        {'$'}
        {amount}
      </Fragment>
    );
  };
  

  const columns = [
    {
        dataField: 'img',
        text: 'Image',
        classes: 'py-2 align-middle',
        formatter: imageFormatter,
        sort: true
    }, {
      dataField: 'id',
      text: 'Name',
      classes: 'py-2 align-middle',
      formatter: detailLinkFormatter,
      sort: true
    }, {
      dataField: 'name',
      text: 'Name',
      classes: 'py-2 align-middle',
      sort: true
    }, {
      dataField: 'description',
      text: 'Description',
      classes: 'py-2 align-middle',
      headerStyle: () => {return {width:'50%'}},
      sort: true
    }, {
      dataField: 'weekstoharvest',
      text: 'Weeks to Harvest',
      classes: 'py-2 align-middle',
      headerStyle: () => {return {width:'20%'}},
      sort: true
    }
  ]

const SeedPodTableNext = (props) => {

  const [showCreateNew, setShowCreateNew] = useState(false)
  const [createNewError, setCreateNewError] = useState(null)

  const createNew = async(name, description, weeks, img, weight) => {
    setCreateNewError(null)
    await HTTP.put(SEEDPOD_SERVICE_URL, {
      name: name,
      description: description,
      weekstoharvest: weeks,
      img: img,
      weight: weight
    })
    .then(response => {
      props.addSeedpod(response.data.id, response.data.name, response.data.description, response.data.weekstoharvest, response.data.img)
      setShowCreateNew(false)
      return response
    })
    .catch(err => {
      console.log(JSON.stringify(err.response.data))
      if (err.response.data.message === "child \"weekstoharvest\" fails because [\"weekstoharvest\" must be a number]") {
        setCreateNewError('Weeks to harvest must be a number.')
      } else if (err.response.data.message === "child \"img\" fails because [\"img\" must be a valid uri]") {
        setCreateNewError('Image URL must be a valid URL.')
      } else if (err.response.data.message === "child \"weight\" fails because [\"weight\" must be a number]") {
        setCreateNewError('Weight must be a number.')
      } else if (err.response.data.message.includes("Duplicate active seedpod name.")) {
        setCreateNewError('A seedpod with that name already exists.')
      } else {
        setCreateNewError('Something went wrong. Try again later.')
      }
    })
  }

  if(!props.isFetching) {
    return (
        <Card className="mb-3">
          <CreateNewForm show={showCreateNew} hide={_ => setShowCreateNew(false)} handleSubmit={createNew} error={createNewError} />
          <GropodCardHeader title="SeedPods" light={false}>
          <Fragment>
            <ButtonIcon icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm" onClick={_ => setShowCreateNew(true)}>
              New
            </ButtonIcon>
            <ButtonIcon icon="filter" transform="shrink-3 down-2" color="falcon-default" size="sm" className="mx-2">
              Filter
            </ButtonIcon>
            <ButtonIcon icon="external-link-alt" transform="shrink-3 down-2" color="falcon-default" size="sm">
              Export
            </ButtonIcon>
          </Fragment>
          </GropodCardHeader>
          <CardBody className="p-0">
                    <div className="table-responsive">
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={props.data}
                        loading={ props.isFetching }
                        columns={columns}
                        bordered={false}
                        striped
                        // classes="table-dashboard table-striped table-sm  border-bottom mb-0 table-dashboard-th-nowrap"
                        rowClasses="btn-reveal-trigger"
                        headerClasses="bg-200 text-900"                  
                      />
                    </div> 
            </CardBody>
          </Card>
        );
      }
      else {
        return(
          <p>{props.isFetching ? 'Fetching Seedpods...' : ''}</p>
        )
      }
};

export default SeedPodTableNext
