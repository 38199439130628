import React, {useEffect, useState} from 'react';
import HTTP from "../../hoc/HTTP";
import SeedpodPackageTable from "./SeedpodPackageTable";

const USER_SERVICE_URL = `${process.env.REACT_APP_API_BASE_URL}/seedpod-package`;


function SeedpodPackages() {
    const [data, setData] = useState({spps: [], isFetching: false});

    useEffect(() => {
        const fetchSpps = async () => {
            try {
                setData({isFetching: true});
                const response = await HTTP.get(USER_SERVICE_URL);
                setData({spps: response.data, isFetching: false});
            } catch (e) {
                console.log(e);
                setData({isFetching: false});
            }
        };
        fetchSpps();
    }, []);

    const addNewPackage = seedpodPackage => {
        let tmp = [...data.spps]
        tmp.push(seedpodPackage)
        setData({ ...data, spps: tmp })
    }

    return (
        <SeedpodPackageTable 
            data={data.spps}
            isFetching={data.isFetching}
            addPackage={addNewPackage}
        />
    )
}

export default SeedpodPackages
