import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

const CreateNew = ({ show, hide, handleSubmit, error }) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [weeksToHarvest, setWeeksToHarvet] = useState('')
    const [img, setImg] = useState('')
    const [weight, setWeight] = useState('')

    return (
        <Modal show={show} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>Create New</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={e => {
                    e.preventDefault()
                    handleSubmit(name, description, weeksToHarvest, img, weight)
                }}>
                    <label>Name</label>
                    <input required className="form-control" value={name} onChange={e => setName(e.target.value)} />
                    <label>Description</label>
                    <input required className="form-control" value={description} onChange={e=> setDescription(e.target.value)} />
                    <label>Weeks to Harvet</label>
                    <input required className="form-control" value={weeksToHarvest} onChange={e => setWeeksToHarvet(e.target.value)} />
                    <label>Image URL</label>
                    <input required className="form-control" value={img} onChange={e => setImg(e.target.value)} />
                    <label>Weight</label>
                    <input required className="form-control" value={weight} onChange={e => setWeight(e.target.value)} />
                    <input className="form-control my-3" type="submit" />
                    { error && <p className="text-danger text-center my-1" >{error}</p> }
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateNew