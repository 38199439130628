import React, {useEffect, useState} from 'react';
import ProductTable from "./ProductTable";
import HTTP from "../../hoc/HTTP";

const PRODUCT_SERVICE_URL = `${process.env.REACT_APP_API_BASE_URL}/products`;


function Products() {
    const [data, setData] = useState({products: [], isFetching: false});

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setData({isFetching: true});
                const response = await HTTP.get(PRODUCT_SERVICE_URL);
                setData({products: response.data, isFetching: false});
            } catch (e) {
                console.log(e);
                setData({isFetching: false});
            }
        };
        fetchProducts();
    }, []);

    return <ProductTable data={data.products}  isFetching={data.isFetching}
    />
}

export default Products
